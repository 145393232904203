body[data-theme="dark"] {
  --font-color: rgb(240, 240, 240);
  --background-color: #0a44a0;
  --card-hover-bg: rgba(255, 255, 255, 0.12);
}

body[data-theme="light"] {
  --font-color: rgb(10, 10, 10);
  --background-color: #f6f6f6;
  --card-hover-bg: rgba(0, 0, 0, 0.12);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--background-color);
  color: var(--font-color);
  transition-property: color, background-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.Header {
  padding: 4vh 2vw;
  font-weight: 600;
  color: var(--font-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header__inner {
  display: flex;
  align-items: center;
}

.Header__inner h2 {
  margin: 0;
}

img.logo {
  width: 200px;
  max-width: 60%;
}

body[data-theme="dark"] img.logo {
  filter: brightness(100);
}

.row {
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

a,
a:hover {
  text-decoration: none;
  color: var(--font-color);
}

.Card {
  margin-top: 5%;
  margin-right: 2%;
  padding: 8px;
  border-radius: 8px;
  height: 200px;
}

.Card:hover {
  background: var(--card-hover-bg);
  border: 1px solid hsla(0, 0%, 87.8%, 0);
}

.data {
  margin-top: 5px;
}

h2 {
  color: var(--font-color);
  font-size: 140%;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 1px;
  display: inline;
}

p {
  font-size: 100%;
  font-weight: 400;
  margin-bottom: 5px;
}

.cover {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 94px;
}

.Footer {
  padding: 4vh 4vw;
  font-size: small;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

body[data-theme="light"] .Footer {
  filter: invert(1);
}

body[data-theme="light"] .Footer p {
  color: #0a44a0;
  filter: invert(1);
}

.Footer p {
  text-align: left;
  color: var(--font-color);
  font-weight: 600;
  margin-right: 30px;
}

.Footer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Footer .footer-logo {
  height: 60px;
}

.text-center {
  text-align: center;
}

body[data-theme="light"] .Footer .footer-logo {
  filter: invert(18%) sepia(83%) saturate(2163%) hue-rotate(-50deg)
    brightness(92%) contrast(97%);
}

.switch-theme-button {
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

body[data-theme="dark"] .switch-theme-button {
  background: linear-gradient(#ff7a41, #ffab00);
}

body[data-theme="light"] .switch-theme-button {
  background: linear-gradient(#0090f6, #0a44a0);
}

.switch-theme-button {
  transform: scale(1.05) rotate(-1deg);
}

@media (max-width: 550px) {
  .Header {
    padding: 4vh 6vw;
  }

  .Footer {
    flex-direction: row;
  }

  .Footer p {
    width: 80px;
  }

  .Footer > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .Footer .footer-logo {
    height: 40px;
  }
}
